/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
// ErrorPage.js
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { storeAction } from "../Store/Store";
import axios from "axios";

const ErrorPage = () => {
  const { token } = useSelector((store) => store);
  const dispatch = useDispatch();
  useEffect(() => {
    checkStatus();
  }, []);
  const checkStatus = async () => {
    try {
      const response = await axios
        .post(`${process.env.REACT_APP_LOCAL_HOST_URL}/validate/tokens/`, {
          access_token: token,
          refresh_token: "",
        })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          return err.response;
        });
      if (response.access_token == false) {
        dispatch(storeAction.reset());
        localStorage.removeItem("persist:root");
        localStorage.clear();
        sessionStorage.clear();
        window.location.replace("/#/login");
      } else {
        setTimeout(() => {
          dispatch(storeAction.reset());
          localStorage.removeItem("persist:root");
          localStorage.clear();
          sessionStorage.clear();
          window.location.replace("/#/login");
        }, 5000);
      }
    } catch (err) {
      console.log(err.response?.status);
    }
  };
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <h1 className="text-5xl font-bold text-gray-800">404</h1>
      <p className="text-lg text-gray-600">Page Not Found</p>
      <p className="text-gray-600">
        The page you are looking for does not exist.
      </p>
    </div>
  );
};

export default ErrorPage;
