/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const ProgressBar = ({ setvalueper }) => {
  const userdata = useSelector((store) => store.userdata);
  const [percentage, setpercentage] = useState(0);
  useEffect(() => {
    GetPercentage();
  }, [userdata]);
  const GetPercentage = async () => {
    if (userdata.length !== 0) {
      var count = 0;
      if (userdata[0].address !== null) {
        count += 1;
      }
      if (userdata[0].work_preference_info !== null) {
        count += 1;
      }
      if (
        userdata[0].professional_details_info.length !== 0 ||
        userdata[0].fresher == true ||
        userdata[0].freshers_status == true
      ) {
        count += 1;
      }
      if (
        userdata[0].project_details_info.length !== 0 ||
        userdata[0].fresher == true ||
        userdata[0].freshers_status == true
      ) {
        count += 1;
      }
      if (
        userdata[0].certificate_info.length !== 0 ||
        userdata[0].no_certificate == true
      ) {
        count += 1;
      }
      if (userdata[0].travel_info !== null) {
        count += 1;
      }
      if (userdata[0].education_info.length !== 0) {
        count += 1;
      }
      if (userdata[0].video_resume !== null) {
        if (userdata[0].video_resume.length !== 0) {
          count += 1;
        }
      }
      let percent = Math.round((count / 8) * 100);
      setpercentage(percent);
      setvalueper(percent);
    }
  };
  return (
    
    <div></div>
  );
};

export default ProgressBar;
