/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import { StepLabel } from "@mui/material";

const steps = [
  "Profile Completion 100%",
  "Notice Period",
  "Submit Profile",
  "Tech Assessment",
  "Personality Assessment",
  "BGV Report",
  "Final Approval",
];

export default function HorizontalNonLinearStepper({ userdata }) {
  const [userlevel, setuserlevel] = React.useState(0);
  React.useEffect(() => {
    GetUserinfo();
  }, [userdata]);
  const GetUserinfo = () => {
    if (userdata.length !== 0) {
      var level = 0;
      var count = 0;
      if (userdata[0].address !== null) {
        count += 1;
      }
      if (userdata[0].work_preference_info !== null) {
        count += 1;
      }
      if (
        userdata[0].professional_details_info.length !== 0 ||
        userdata[0].fresher == true ||
        userdata[0].freshers_status == true
      ) {
        count += 1;
      }
      if (
        userdata[0].project_details_info.length !== 0 ||
        userdata[0].fresher == true ||
        userdata[0].freshers_status == true
      ) {
        count += 1;
      }
      if (
        userdata[0].certificate_info.length !== 0 ||
        userdata[0].no_certificate == true
      ) {
        count += 1;
      }
      if (userdata[0].travel_info !== null) {
        count += 1;
      }
      if (userdata[0].education_info.length !== 0) {
        count += 1;
      }
      if (userdata[0].video_resume !== null) {
        if (userdata[0].video_resume.length !== 0) {
          count += 1;
        }
      }
      let percent = Math.round((count / 8) * 100);
      if (
        percent == 100 &&
        userdata[0].notice_period.length !== 0 &&
        userdata[0].availability_from.length !== 0
      ) {
        setuserlevel(1);
        if (
          userdata[0].notice_period.length !== 0 &&
          userdata[0].availability_from.length !== 0
        ) {
          setuserlevel(2);
          if (userdata[0].nottify == true) {
            setuserlevel(3);
            if (
              userdata[0].work_preference_info !== null &&
              userdata[0].work_preference_info.technical_assessment.length !== 0
            ) {
              setuserlevel(4);
              if (
                userdata[0].personality_assessment !== null &&
                userdata[0].personality_assessment.length !== 0
              ) {
                setuserlevel(5);
                if (
                  userdata[0].background_verification !== null &&
                  userdata[0].background_verification.length !== 0
                ) {
                  setuserlevel(6);
                  if (
                    userdata[0].vetted == true &&
                    userdata[0].apprual == true
                  ) {
                    setuserlevel(7);
                  }
                }
              }
            }
          }
        }
      }

      // if (userdata[0].non_vetted == true) {
      //   setuserlevel(3);
      // }
    }
  };
  return (
    <div>
      <Box sx={{ width: "100%", marginTop: "10px", marginBottom: "10px" }}>
        <Stepper activeStep={userlevel} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    </div>
  );
}
