/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaRegCircleXmark } from "react-icons/fa6";
import { FiPlusCircle } from "react-icons/fi";
import { IoEye } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { storeAction } from "../../../Store/Store";
import * as XLSX from "xlsx";

const PostMouPreApprovalReview = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((store) => store.token);
  const [teamlist, setteamlist] = useState([
    {
      id: uuidv4(),
      first_name: "",
      last_name: "",
      recruiter_reviewer: "",
      mou: "",
      video_resume: "",
      personal_details: "",
      tech_assessment_report: "",
      tech_assessment_interview_link: "",
      bgv: "",
      bgv_intiative: "",
      work_experience: "",
      projects: "",
      education: "",
      previous_salaries: "",
      certifications: "",
      availability_tab_approved: "",
      comments: "",
      date_review_completed: "",
      send_for_approval: "",
      pre_send_for_approval: "",
      isNew: true,
      move: "false",
    },
  ]);
  const [loading, setloading] = useState(false);
  const [loading1, setloading1] = useState(false);
  const [count, setcount] = useState(0);
  const [pagination_status, setpagination_status] = useState(false);
  const [newurl, setnewurl] = useState(null);
  const [candidatenext, setcandidatenext] = useState(null);
  const [candidateprevious, setcandidateprevious] = useState(null);
  const [finaldata, setfinaldata] = useState([]);
  const [searchname, setsearchname] = useState("");
  const [searchfilter, setsearchfilter] = useState(false);
  const [searchloading, setsearchloading] = useState(false);

  const handleChangeNew = (index, field, value) => {
    const updatedTeams = teamlist.map((candidate, cIndex) => {
      if (cIndex === index) {
        return {
          ...candidate,
          [field]: value,
        };
      }
      return candidate;
    });
    setteamlist(updatedTeams);
  };

  // const addCandidate = () => {
  //   const newCandidate = {
  //     id: uuidv4(),
  //     first_name: "",
  //     last_name: "",
  //     recruiter_reviewer: "",
  //     mou: "",
  //     video_resume: "",
  //     personal_details: "",
  //     tech_assessment_report: "",
  //     tech_assessment_interview_link: "",
  //     bgv: "",
  //     bgv_intiative: "",
  //     work_experience: "",
  //     projects: "",
  //     education: "",
  //     previous_salaries: "",
  //     certifications: "",
  //     availability_tab_approved: "",
  //     comments: "",
  //     date_review_completed: "",
  //     send_for_approval: "",
  //     pre_send_for_approval: "",
  //     isNew: true,
  //     move: "false",
  //   };

  //   setteamlist([...teamlist, newCandidate]);
  // };

  // const removeCandidate = (candidateId) => {
  //   const updatedTeamlist = teamlist.filter(
  //     (candidate) => candidate.id !== candidateId
  //   );
  //   setteamlist(updatedTeamlist);
  // };

  const submitbtn = async () => {
    if (teamlist.length !== 0) {
      setloading(true);
      dispatch(storeAction.isPopUpHander("loading"));
      for (let i = 0; i < teamlist.length; i++) {
        const candidate = teamlist[i];
        const newCandidateData = {
          first_name: candidate.first_name,
          last_name: candidate.last_name,
          recruiter_reviewer: candidate.recruiter_reviewer,
          mou: candidate.mou,
          video_resume: candidate.video_resume,
          personal_details: candidate.personal_details,
          tech_assessment_report: candidate.tech_assessment_report,
          tech_assessment_interview_link:
            candidate.tech_assessment_interview_link,
          bgv: candidate.bgv,
          work_experience: candidate.work_experience,
          projects: candidate.projects,
          education: candidate.education,
          previous_salaries: candidate.previous_salaries,
          certifications: candidate.certifications,
          availability_tab_approved: candidate.availability_tab_approved,
          comments: candidate.comments,
          date_review_completed: candidate.date_review_completed,
          send_for_approval: candidate.send_for_approval,
        };
        if (candidate.isNew) {
          await axios
            .post(
              `${process.env.REACT_APP_LOCAL_HOST_URL}/mou-approval/`,
              newCandidateData,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `JWT ${token}`,
                },
              }
            )
            .then((res) => {
              candidate.isNew = false;
            })
            .catch((err) => console.error(err));
        } else {
          await axios
            .put(
              `${process.env.REACT_APP_LOCAL_HOST_URL}/mou-approval/${candidate.id}/`,
              newCandidateData,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `JWT ${token}`,
                },
              }
            )
            .catch((err) => console.error(err));
        }
      }
      setloading(false);
      setTimeout(() => {
        dispatch(storeAction.isPopUpHander());
      }, 2000);
    }
  };

  useEffect(() => {
    Getalldata();
  }, []);

  const Getalldata = async () => {
    const Alldata = await axios
      .get(`${process.env.REACT_APP_LOCAL_HOST_URL}/mou-approval/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
      })
      .then((res) => res.data)
      .catch((err) => err.response);

    if (Alldata.results.length !== 0) {
      setcount(Alldata.count);
      if (Alldata.count > 20) {
        setpagination_status(true);
      } else {
        setpagination_status(false);
      }
      setcandidateprevious(Alldata.previous);
      setcandidatenext(Alldata.next);
      const updatedData = Alldata.results.map((candidate) => ({
        ...candidate,
        isNew: false,
        move: "false",
      }));
      setteamlist(updatedData);
    }
    const Allfinaldata = await axios
      .get(`${process.env.REACT_APP_LOCAL_HOST_URL}/final-approval/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
      })
      .then((res) => res.data)
      .catch((err) => err.response);

    if (Allfinaldata.results.length !== 0) {
      setfinaldata(Allfinaldata.results);
    }
  };
  const moveToass = async () => {
    setloading1(true);
    dispatch(storeAction.isPopUpHander("loading"));
    teamlist.forEach(async (teamMember) => {
      const matchedAssessment = finaldata.find(
        (assessment) => assessment.email === teamMember.email
      );
      if (teamMember.move == "true") {
        if (matchedAssessment) {
          const obj = {
            email: teamMember.email,
            first_name: teamMember.first_name,
            last_name: teamMember.last_name,
          };
          await axios
            .put(
              `${process.env.REACT_APP_LOCAL_HOST_URL}/final-approval/${matchedAssessment.id}/`,
              obj,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `JWT ${token}`,
                },
              }
            )
            .catch((err) => console.error(err));
        } else {
          const obj1 = {
            email: teamMember.email,
            first_name: teamMember.first_name,
            last_name: teamMember.last_name,
          };
          await axios
            .post(
              `${process.env.REACT_APP_LOCAL_HOST_URL}/final-approval/`,
              obj1,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `JWT ${token}`,
                },
              }
            )
            .catch((err) => console.error(err));
        }
      }
    });
    Getalldata();
    setTimeout(() => {
      setloading1(false);
      dispatch(storeAction.isPopUpHander());
    }, 2000);
  };

  const exportBtn = async () => {
    if (teamlist.length !== 0) {
      const manipulatedData = teamlist.map((item) => {
        return {
          First_Name: item.first_name,
          Last_Name: item.last_name,
          Recruiter_Reviewer: item.recruiter_reviewer,
          Mou: item.mou,
          Video_Resume: item.video_resume,
          Personal_Details: item.personal_details,
          Tech_Assessment_Report: item.tech_assessment_report,
          Tech_Assessment_Interview_Link: item.tech_assessment_interview_link,
          BGV: item.bgv,
          Work_Experience: item.work_experience,
          Projects: item.projects,
          Education: item.education,
          Certifications: item.certifications,
          Previous_Salaries: item.previous_salaries,
          Availability_Tab_Approved: item.availability_tab_approved,
          Comments: item.comments,
          Date_Review_Completed: item.date_review_completed,
          Send_For_Approval: item.send_for_approval,
        };
      });
      const worksheet = XLSX.utils.json_to_sheet(manipulatedData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, "Post Mou PreApproval Review.xlsx");
    }
  };

  useEffect(() => {
    GetnewCandidate();
  }, [newurl]);

  const GetnewCandidate = async () => {
    if (newurl !== null) {
      dispatch(storeAction.isPopUpHander("loading"));
      const response = await axios
        .get(`${newurl}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        })
        .then((res) => {
          return res.data;
        });
      if (response.results.length !== 0) {
        setcount(response.count);
        if (response.count > 20) {
          setpagination_status(true);
        } else {
          setpagination_status(false);
        }
        setcandidateprevious(response.previous);
        setcandidatenext(response.next);
        const updatedData = response.results.map((candidate) => ({
          ...candidate,
          isNew: false,
          move: "false",
        }));
        setteamlist(updatedData);
        dispatch(storeAction.isPopUpHander());
      }
    }
  };

  const searchbtn = async () => {
    if (searchname.length !== 0) {
      setsearchloading(true);
      var obj = {
        name: searchname,
      };
      const Alldata = await axios
        .post(
          `${process.env.REACT_APP_LOCAL_HOST_URL}/mou-pre-approval-name-filters/`,
          obj,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${token}`,
            },
          }
        )
        .then((res) => res.data)
        .catch((err) => err.response);
      if (Alldata.results.length !== 0) {
        setsearchloading(false);
        setsearchfilter(true);
        setcount(Alldata.count);
        if (Alldata.count > 20) {
          setpagination_status(true);
        } else {
          setpagination_status(false);
        }
        setcandidateprevious(Alldata.previous);
        setcandidatenext(Alldata.next);
        const updatedData = Alldata.results.map((candidate) => ({
          ...candidate,
          isNew: false,
          isFrom: "db",
        }));
        setteamlist(updatedData);

        dispatch(storeAction.isPopUpHander());
      } else {
        setsearchloading(false);
        setsearchfilter(false);
      }
    } else {
      setsearchloading(false);
      setsearchfilter(false);
      alert("Please Enter Search First & Middle Name or Last Name...");
    }
  };

  const nPages = Math.ceil(count / 20);
  const pageNumbers = Array.from({ length: nPages }, (_, i) => i + 1);

  return (
    <div>
      <button className="importbtn" onClick={exportBtn}>
        Export
      </button>
      <div className="flex gap-2 items-center">
        <input
          type="text"
          className="w-[20%] mt-2 mb-4 px-4 py-2 border border-gray-200 rounded"
          placeholder="Search First & Middle Name or Last Name"
          onChange={(e) => setsearchname(e.target.value)}
          value={searchname}
        />
        {searchloading == true ? (
          <button className="w-[5%] mt-2 mb-4 px-4 py-2 border border-[#1D37E7] rounded bg-[#1D37E7] text-white font-medium">
            Searching...
          </button>
        ) : (
          <button
            className="w-[5%] mt-2 mb-4 px-4 py-2 border border-[#1D37E7] rounded bg-[#1D37E7] text-white font-medium"
            onClick={searchbtn}
          >
            Search
          </button>
        )}

        {searchfilter && (
          <button
            className="w-[5%] mt-2 mb-4 px-4 py-2 border border-[#1D37E7] rounded bg-black text-white font-medium"
            onClick={() => {
              setsearchfilter(false);
              Getalldata();
              setsearchname("");
            }}
          >
            Cancel
          </button>
        )}
      </div>
      <table>
        <thead>
          <tr className="hireTableHead">
            <th>Action</th>
            <th>View</th>
            <th>First & Middle Name</th>
            <th>Last Name</th>
            <th>Recruiter</th>
            <th>Mou</th>
            <th>Video Resume</th>
            <th>Personal Details</th>
            <th>Tech Assessment Report</th>
            <th>Tech Assessment Interview Link</th>
            <th>Vendor BGV Intiating</th>
            <th>BGV</th>
            <th>Work Exp</th>
            <th>Projects</th>
            <th>Education</th>
            <th>Certifications</th>
            <th>Previous salaries</th>
            <th>Availability Tab Approved</th>
            <th>Comments</th>
            <th>Date Review Completed</th>
            <th>Pre Approval Authority</th>
            <th>Send for Approval</th>
          </tr>
        </thead>
        <tbody className="hireTableBody">
          {teamlist.length !== 0 &&
            teamlist.map((role, roleIndex) => (
              <tr className="hireTablerow" key={roleIndex}>
                {/* <td>
                  <div className="flex items-center justify-center">
                    <FaRegCircleXmark
                      className="xIcon"
                      onClick={() => removeCandidate(0, role.id)}
                    />
                    <FiPlusCircle
                      className="plusIcon"
                      onClick={() => addCandidate(0)}
                    />
                  </div>
                </td> */}
                <td>
                  <h3>
                    <input
                      type="checkbox"
                      checked={role.move == "true"}
                      onChange={() => {
                        handleChangeNew(
                          roleIndex,
                          "move",
                          role.move == "true" ? "false" : "true"
                        );
                      }}
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    {role.staff_data !== null ? (
                      <IoEye
                        className="plusIcon cursor-pointer"
                        onClick={() => {
                          dispatch(
                            storeAction.singleuserHander({
                              singleuser: [role.staff_data],
                            })
                          );
                          navigate("/admincandidateview");
                        }}
                      />
                    ) : null}
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.first_name}
                      type="text"
                      disabled
                      onChange={(e) =>
                        handleChangeNew(roleIndex, "first_name", e.target.value)
                      }
                    />
                    {/* {role.first_name} */}
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.last_name}
                      type="text"
                      disabled
                      onChange={(e) =>
                        handleChangeNew(roleIndex, "last_name", e.target.value)
                      }
                    />
                    {/* {role.last_name} */}
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.recruiter_reviewer}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "recruiter_reviewer",
                          e.target.value
                        )
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.mou}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(roleIndex, "mou", e.target.value)
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.video_resume}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "video_resume",
                          e.target.value
                        )
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.personal_details}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "personal_details",
                          e.target.value
                        )
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.tech_assessment_report}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "tech_assessment_report",
                          e.target.value
                        )
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.tech_assessment_interview_link}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "tech_assessment_interview_link",
                          e.target.value
                        )
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <select
                      className="hireTableBodySelect"
                      value={role.bgv_intiative}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "bgv_intiative",
                          e.target.value
                        )
                      }
                    >
                      <option value="">Select</option>
                      <option value="Sanskriti">Sanskriti</option>
                      <option value="Sanchan">Sanchan</option>
                      <option value="Divya">Divya</option>
                      <option value="Shikha">Shikha</option>
                      <option value="Priya">Priya</option>
                    </select>
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role._intiative}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(roleIndex, "bgv", e.target.value)
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.work_experience}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "work_experience",
                          e.target.value
                        )
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.projects}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(roleIndex, "projects", e.target.value)
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.education}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(roleIndex, "education", e.target.value)
                      }
                    />
                  </h3>
                </td>

                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.certifications}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "certifications",
                          e.target.value
                        )
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.previous_salaries}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "previous_salaries",
                          e.target.value
                        )
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.availability_tab_approved}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "availability_tab_approved",
                          e.target.value
                        )
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.comments}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(roleIndex, "comments", e.target.value)
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.date_review_completed}
                      type="date"
                      min="1000-01-01"
                      max="9999-12-31"
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "date_review_completed",
                          e.target.value
                        )
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <select
                      className="hireTableBodySelect"
                      value={role.pre_send_for_approval}
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "pre_send_for_approval",
                          e.target.value
                        )
                      }
                    >
                      <option value="">Select</option>
                      <option value="Shikha">Shikha</option>
                      <option value="Divya">Divya</option>
                    </select>
                  </h3>
                </td>
                <td>
                  <h3>
                    <select
                      className="hireTableBodySelect"
                      value={role.send_for_approval}
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "send_for_approval",
                          e.target.value
                        )
                      }
                    >
                      <option value="">Select</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </h3>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      {loading ? (
        <button className="saveSubmit">Please Wait...</button>
      ) : (
        <button className="saveSubmit" onClick={submitbtn}>
          Save Changes
        </button>
      )}
      {loading1 ? (
        <button className="saveSubmit1">Please Wait...</button>
      ) : (
        <button className="saveSubmit1" onClick={moveToass}>
          Move to Final Approval
        </button>
      )}
      {pagination_status && (
        <div className="tablePagination">
          <nav>
            <ul className="pagination">
              {candidateprevious == null ? (
                <li className="page-item disabled">
                  <h6 className="page-link">&lt;&lt; Previous</h6>
                </li>
              ) : (
                <li
                  className="page-item active"
                  onClick={() => {
                    setnewurl(candidateprevious);
                  }}
                >
                  <h6 className="page-link">&lt;&lt; Previous</h6>
                </li>
              )}

              {newurl == null ? (
                <h6 className="page-link">1</h6>
              ) : (
                <h6 className="page-link active">
                  {newurl.includes("/mou-approval/") &&
                  newurl.split("/mou-approval/")[1]?.split("?page=")[1] !==
                    undefined
                    ? newurl.split("/mou-approval/")[1].split("?page=")[1]
                    : 1}
                </h6>
              )}
              {candidatenext == null ? (
                <li className="page-item disabled">
                  <h6 className="page-link">Next &gt;&gt;</h6>
                </li>
              ) : (
                <li
                  className="page-item active"
                  onClick={() => {
                    setnewurl(candidatenext);
                  }}
                >
                  <h6 className="page-link">Next &gt;&gt;</h6>
                </li>
              )}
            </ul>
          </nav>
          <p className="text-center mt-4 font-medium text-xs text-[#71717a]">
            Total No Of Pages : {pageNumbers.length}
          </p>
        </div>
      )}
    </div>
  );
};

export default PostMouPreApprovalReview;
