/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import AdminClientProfileComp from "../Components/AdminScreen/AdminProfile/AdminClientProfile/AdminClientProfileComp";
import AdminCandidateProfile from "../Components/AdminScreen/AdminProfile/AdminCandidateProfile/AdminCandidateProfile";
import DashHead from "../Components/Reusable/DashBoardReusable/DashHead/DashHead";
import "../Components/AdminScreen/AdminProfile/AdminCandidateProfile/AdminCandidateProfile.css";
import country_and_states from "../assests/country-states";
import { useDispatch, useSelector } from "react-redux";
import { storeAction } from "../Store/Store";
import axios from "axios";
import VendorCandidateProfile from "../Components/AdminScreen/AdminProfile/AdminCandidateProfile/VendorCandidateProfile";

const AdminCustomerProfile = () => {
  const dispatch = useDispatch();
  const token = useSelector((store) => store.token);
  const alluserdata = useSelector((store) => store.alluserdata || []);
  const allcompanydata = useSelector((store) => store.allcompanydata || []);

  const [isButton, setIsButton] = useState("candidate");
  const [alldata, setalldata] = useState([]);
  const [totaldata, settotaldata] = useState([]);
  const [loading, setloading] = useState(true);
  const [alldata1, setalldata1] = useState([]);
  const [totaldata1, settotaldata1] = useState([]);
  const [fullload1, setfullload1] = useState(true);
  const [vendoralldata, setvendoralldata] = useState([]);
  const [vendortotaldata, setvendortotaldata] = useState([]);
  const [candidatenext, setcandidatenext] = useState(null);
  const [candidateprevious, setcandidateprevious] = useState(null);
  const [newurl, setnewurl] = useState(null);
  const [count, setcount] = useState(0);
  const [pagination_status, setpagination_status] = useState(false);
  const [statussearch, setstatussearch] = useState(false);
  const [statussearchvalue, setstatussearchvalue] = useState("");

  const buttonHandler = (event) => {
    setIsButton(event.target.id);
    setcandidatenext(null);
    setcandidateprevious(null);
    setnewurl(null);
    setcount(0);
    setpagination_status(true);
  };

  useEffect(() => {
    GetallCandidate();
    getallclient();
  }, []);

  const GetallCandidate = async () => {
    dispatch(storeAction.isPopUpHander("loading"));
    const response = await axios
      .get(`${process.env.REACT_APP_LOCAL_HOST_URL}/onboardedFaculties/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
      })
      .then((res) => {
        return res.data;
      });
    if (response.results.length !== 0) {
      dispatch(storeAction.isPopUpHander());
      setcount(response.count);
      if (response.count > 20) {
        setpagination_status(true);
      } else {
        setpagination_status(false);
      }
      const allFaculties = response.results || [];
      const sortedCandidates = [...allFaculties].sort((a, b) => {
        return new Date(b.updated_at) - new Date(a.updated_at);
      });
      setstatussearch(false);
      setcandidateprevious(response.previous);
      setcandidatenext(response.next);
      setalldata(sortedCandidates);
      settotaldata(sortedCandidates);
      dispatch(
        storeAction.alluserdataHander({
          alluserdata: sortedCandidates || [],
        })
      );
    } else {
      dispatch(storeAction.isPopUpHander());
    }

    const response1 = await axios
      .get(`${process.env.REACT_APP_LOCAL_HOST_URL}/vendorCandidate/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
      })
      .then((res) => {
        return res.data;
      });
    if (response1.results.length !== 0) {
      // setcount(response1.count);
      // if (response1.count > 20) {
      //   setpagination_status(true);
      // } else {
      //   setpagination_status(false);
      // }
      const allFaculties = response1.results || [];
      const sortedCandidates = [...allFaculties].sort((a, b) => {
        return new Date(b.updated_at) - new Date(a.updated_at);
      });
      // setcandidateprevious(response1.previous);
      // setcandidatenext(response1.next);
      setvendoralldata(sortedCandidates);
      setvendortotaldata(sortedCandidates);
    }
  };

  const getallclient = async () => {
    if (allcompanydata.length !== 0) {
      setloading(false);
      setfullload1(true);
      const allFaculties1 = allcompanydata || [];
      const sortedCandidates1 = [...allFaculties1].sort((a, b) => {
        return new Date(b.updated_at) - new Date(a.updated_at);
      });
      setalldata1(sortedCandidates1);
      settotaldata1(sortedCandidates1);
      const response = await axios.get(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/getCompanies`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        }
      );
      const allfacility = response.data;
      const allFaculties2 = allfacility.companies || [];
      const sortedClient = [...allFaculties2].sort((a, b) => {
        return new Date(b.updated_at) - new Date(a.updated_at);
      });
      setalldata1(sortedClient || []);
      settotaldata1(sortedClient || []);
      dispatch(
        storeAction.allcompanydataHander({
          allcompanydata: sortedClient || [],
        })
      );
      setfullload1(false);
    } else {
      setfullload1(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_LOCAL_HOST_URL}/getCompanies`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${token}`,
            },
          }
        );
        const allfacility = response.data;
        const allFaculties2 = allfacility.companies || [];
        const sortedClient = [...allFaculties2].sort((a, b) => {
          return new Date(b.updated_at) - new Date(a.updated_at);
        });
        setalldata1(sortedClient || []);
        settotaldata1(sortedClient || []);
        dispatch(
          storeAction.allcompanydataHander({
            allcompanydata: sortedClient || [],
          })
        );
        setfullload1(false);
      } catch (error) {
        setfullload1(false);
      } finally {
        setloading(false);
        setfullload1(false);
      }
    }
  };

  const changecountry = async (e) => {
    if (e.length !== 0) {
      if (totaldata.length !== 0) {
        const matchingSkills = totaldata.filter((skill) => {
          if (skill.address !== null) {
            return skill.address.country
              .toLowerCase()
              .includes(e.toLowerCase());
          }
        });
        setalldata(matchingSkills);
      }
    } else {
      setTimeout(() => {
        setalldata(alluserdata);
      }, 10);
      setalldata(totaldata);
    }
  };
  const changecountry1 = async (e) => {
    if (e.length !== 0) {
      if (vendortotaldata.length !== 0) {
        const matchingSkills = vendortotaldata.filter((skill) => {
          if (skill.address !== null) {
            return skill.address.country
              .toLowerCase()
              .includes(e.toLowerCase());
          }
        });
        setvendoralldata(matchingSkills);
      }
    } else {
      setTimeout(() => {
        setvendoralldata(alluserdata);
      }, 10);
      setvendoralldata(totaldata);
    }
  };
  const changetype = async (e) => {
    if (e.length !== 0) {
      if (totaldata1.length !== 0) {
        const matchingSkills = totaldata1.filter((skill) => {
          if (skill.pricing_info.length !== 0) {
            return skill.pricing_info[0].pricing_plan
              .toLowerCase()
              .includes(e.toLowerCase());
          }
        });
        setalldata1(matchingSkills);
      }
    } else {
      setTimeout(() => {
        setalldata1(allcompanydata);
      }, 10);
      setalldata1(totaldata1);
    }
  };

  const changestatus = async (value) => {
    if (totaldata1.length !== 0) {
      if (value === "Active") {
        const active = totaldata1.filter((data) => data.dissabled === false);
        setalldata1(active);
      } else if (value === "Inactive") {
        const inactive = totaldata1.filter((data) => data.dissabled === true);
        setalldata1(inactive);
      } else {
        setalldata1(totaldata1);
      }
    }
  };
  const calculateProfileCompletion = (profile) => {
    let count = 0;
    if (profile.address !== null) count += 1;
    if (profile.work_preference_info !== null) count += 1;
    if (
      profile.professional_details_info.length !== 0 ||
      profile.fresher == true ||
      profile.freshers_status == true
    )
      count += 1;
    if (
      profile.project_details_info.length !== 0 ||
      profile.fresher == true ||
      profile.freshers_status == true
    )
      count += 1;
    if (profile.certificate_info.length !== 0 || profile.no_certificate == true)
      count += 1;
    if (profile.travel_info !== null) count += 1;
    if (profile.education_info.length !== 0) count += 1;
    if (profile.video_resume !== null && profile.video_resume.length !== 0)
      count += 1;

    return count;
  };
  const change_status = async (e) => {
    // if (totaldata.length !== 0) {
    //   let filteredData = [];
    //   if (e === "Benched") {
    //     filteredData = totaldata.filter(
    //       (data) =>
    //         (data.status === "Benched" || data.status === "Success") &&
    //         data.nottify === true &&
    //         data.apprual === true
    //     );
    //   } else if (e === "Hired") {
    //     filteredData = totaldata.filter((data) => data.status === "Hired");
    //   } else if (e === "Reserved") {
    //     filteredData = totaldata.filter((data) => data.status === "Reserved");
    //   } else if (e === "Submitted") {
    //     filteredData = totaldata.filter((data) => {
    //       const profileCompletion = calculateProfileCompletion(data);
    //       const percent = Math.round((profileCompletion / 8) * 100);
    //       return (
    //         (data.status === "Success" ||
    //           data.status === "Mail send" ||
    //           data.status === "Benched") &&
    //         data.nottify === true &&
    //         data.apprual === false &&
    //         percent === 100
    //       );
    //     });
    //   } else if (e === "Onboarding") {
    //     filteredData = totaldata.filter((data) => data.nottify !== true);
    //   } else {
    //     filteredData = totaldata;
    //   }
    //   setalldata(filteredData);
    // } else {
    //   setalldata(totaldata);
    // }
    if (e !== "All") {
      dispatch(storeAction.isPopUpHander("loading"));
      setstatussearchvalue(e);
      const response = await axios
        .post(
          `${process.env.REACT_APP_LOCAL_HOST_URL}/status/`,
          {
            status: e,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${token}`,
            },
          }
        )
        .then((res) => {
          return res.data;
        });
      if (response.results.length !== 0) {
        dispatch(storeAction.isPopUpHander());
        setcount(response.count);
        if (response.count > 20) {
          setpagination_status(true);
        } else {
          setpagination_status(false);
        }
        const allFaculties = response.results || [];
        const sortedCandidates = [...allFaculties].sort((a, b) => {
          return new Date(b.updated_at) - new Date(a.updated_at);
        });
        setstatussearch(true);
        setcandidateprevious(response.previous);
        setcandidatenext(response.next);
        setalldata(sortedCandidates);
        settotaldata(sortedCandidates);
      } else {
        dispatch(storeAction.isPopUpHander());
        setcount(0);
        setstatussearch(false);
        setcandidateprevious(null);
        setcandidatenext(null);
        setalldata([]);
        settotaldata([]);
      }
    } else {
      GetallCandidate();
    }
  };
  const change_status1 = async (e) => {
    if (vendortotaldata.length !== 0) {
      let filteredData = [];
      if (e === "Benched") {
        filteredData = vendortotaldata.filter(
          (data) =>
            (data.status === "Benched" || data.status === "Success") &&
            data.nottify === true &&
            data.apprual === true
        );
      } else if (e === "Hired") {
        filteredData = vendortotaldata.filter(
          (data) => data.status === "Hired"
        );
      } else if (e === "Reserved") {
        filteredData = vendortotaldata.filter(
          (data) => data.status === "Reserved"
        );
      } else if (e === "Submitted") {
        filteredData = vendortotaldata.filter((data) => {
          const profileCompletion = calculateProfileCompletion(data);
          const percent = Math.round((profileCompletion / 8) * 100);
          return (
            (data.status === "Success" ||
              data.status === "Mail send" ||
              data.status === "Benched") &&
            data.nottify === true &&
            data.apprual === false &&
            percent === 100
          );
        });
      } else if (e === "Onboarding") {
        filteredData = vendortotaldata.filter((data) => data.nottify !== true);
      } else {
        filteredData = vendortotaldata;
      }
      setvendoralldata(filteredData);
    } else {
      setvendoralldata(vendortotaldata);
    }
  };
  return (
    <div className="adminProfile paddingLeft50 paddingRight50">
      <DashHead
        head="Profile"
        desc="View and update profiles of onboarded clients and candidates"
        descClass="dashBoardMainHeadDescBetween"
      />
      <div className="shortListButton marginBottom20 ">
        <div className="shortListButton1">
          <button
            id="candidate"
            onClick={buttonHandler}
            className={
              isButton === "candidate"
                ? "shortListButtonActive"
                : "shortListButtonDisable"
            }
          >
            Candidates
          </button>
          <button
            id="client"
            onClick={buttonHandler}
            className={
              isButton === "client"
                ? "shortListButtonActive"
                : "shortListButtonDisable"
            }
          >
            Clients
          </button>
          <button
            id="vendor_candidate"
            onClick={buttonHandler}
            className={
              isButton === "vendor_candidate"
                ? "shortListButtonActive"
                : "shortListButtonDisable"
            }
          >
            Vendor Candidate
          </button>
        </div>
        {isButton === "candidate" && (
          <div className="selectdiv">
            <select
              className="profileselect"
              onChange={(e) => {
                change_status(e.target.value);
              }}

              // placeholder={fullload ? "Please wait..." : "Search..."}
            >
              <option value="" disabled>
                Status
              </option>
              <option value="All">All</option>
              <option value="Benched">Available For Hire</option>
              <option value="Submitted">Submitted</option>
              <option value="Hired">Hired</option>
              <option value="Reserved">Reserved</option>
              <option value="Onboarding">Onboarding</option>
              <option value="Dissabled">Dissabled</option>
              <option value="Non_Vetted">Pre Approve Candidate</option>
            </select>
            <select
              className="profileselect"
              onChange={(e) => {
                changecountry(e.target.value);
              }}
              // disabled={fullload}
            >
              <option value="" disabled>
                Country
              </option>
              <option value="">All Country</option>
              {country_and_states.country.length !== 0
                ? country_and_states.country.map((item, index) => (
                    <option value={item.name} key={index}>
                      {item.name}
                    </option>
                  ))
                : null}
            </select>
          </div>
        )}
        {isButton === "client" && (
          <div className="selectdiv">
            <select
              className="profileselect"
              onChange={(e) => {
                changestatus(e.target.value);
              }}
              disabled={fullload1}
            >
              <option value="" disabled>
                Status
              </option>
              <option value="">All Client</option>
              <option value="Inactive">Inactive</option>
              <option value="Active">Active</option>
            </select>
            <select
              className="profileselect"
              onChange={(e) => {
                changetype(e.target.value);
              }}
              disabled={fullload1}
            >
              <option value="" disabled>
                Subscription Type
              </option>
              <option value="">All Client</option>
              <option value="Starter">Starter</option>
              <option value="Pro">Pro</option>
            </select>
          </div>
        )}
        {isButton === "vendor_candidate" && (
          <div className="selectdiv">
            <select
              className="profileselect"
              onChange={(e) => {
                change_status1(e.target.value);
              }}
              // disabled={fullload}
              // placeholder={fullload ? "Please wait..." : "Search..."}
            >
              <option value="" disabled>
                Status
              </option>
              <option value="">All</option>
              <option value="Benched">Available For Hire</option>
              <option value="Submitted">Submitted</option>
              <option value="Hired">Hired</option>
              <option value="Reserved">Reserved</option>
              <option value="Onboarding">Onboarding</option>
            </select>
            <select
              className="profileselect"
              onChange={(e) => {
                changecountry1(e.target.value);
              }}
              // disabled={fullload}
            >
              <option value="" disabled>
                Country
              </option>
              <option value="">All Country</option>
              {country_and_states.country.length !== 0
                ? country_and_states.country.map((item, index) => (
                    <option value={item.name} key={index}>
                      {item.name}
                    </option>
                  ))
                : null}
            </select>
          </div>
        )}
      </div>
      {isButton === "client" && (
        <AdminClientProfileComp
          alldata1={alldata1}
          totaldata1={totaldata1}
          loading={loading}
          setalldata1={setalldata1}
          fullload1={fullload1}
        />
      )}
      {isButton === "candidate" && (
        <AdminCandidateProfile
          alldata={alldata}
          totaldata={totaldata}
          loading={loading}
          setalldata={setalldata}
          settotaldata={settotaldata}
          candidatenext={candidatenext}
          candidateprevious={candidateprevious}
          setcandidateprevious={setcandidateprevious}
          setcandidatenext={setcandidatenext}
          setnewurl={setnewurl}
          newurl={newurl}
          Getcandidate={GetallCandidate}
          setcount={setcount}
          count={count}
          setpagination_status={setpagination_status}
          pagination_status={pagination_status}
          statussearch={statussearch}
          setstatussearch={setstatussearch}
          statussearchvalue={statussearchvalue}
        />
      )}
      {isButton === "vendor_candidate" && (
        <VendorCandidateProfile
          alldata={vendoralldata}
          totaldata={vendortotaldata}
          loading={loading}
          setalldata={setvendoralldata}
          settotaldata={setvendortotaldata}
          candidatenext={candidatenext}
          candidateprevious={candidateprevious}
          setcandidateprevious={setcandidateprevious}
          setcandidatenext={setcandidatenext}
          setnewurl={setnewurl}
          newurl={newurl}
          Getcandidate={GetallCandidate}
          setcount={setcount}
          count={count}
          setpagination_status={setpagination_status}
          pagination_status={pagination_status}
        />
      )}
    </div>
  );
};

export default AdminCustomerProfile;
