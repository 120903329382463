/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import "./ShareComp.css";
import logo from "../../assests/Logo.svg";
import { useDispatch, useSelector } from "react-redux";
import { storeAction } from "../../Store/Store";
import { PiEyeglasses } from "react-icons/pi";
import SuccessResponse from "../Reusable/SuccessResponse/SuccessResponse";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import { useParams } from "react-router-dom";
import axios from "axios";
import CandidateCard from "./CandidateCard";

const ShareComp = () => {
  let { token } = useParams();
  const dispatch = useDispatch();
  const [page, setPage] = useState("page1");
  const [linkloading, setlinkloading] = useState(false);
  const [email, setemail] = useState("");
  const [decodeemail, setdecodeemail] = useState("");
  const [candidateid, setcandidateid] = useState(null);
  const [message, setMessage] = useState("");
  const [single_user, setsingle_user] = useState([]);
  const [finalstatus, setfinalstatus] = useState(false);

  const [dropDown, setDropDown] = useState("page1");
  const dropDownHandler = (e) => {
    setDropDown(e);
  };
  const [dropDown1, setDropDown1] = useState("");
  const dropDownHandler1 = (e) => {
    setDropDown1(e);
  };
  const [dropDown2, setDropDown2] = useState("page1");
  const dropDownHandler2 = (e) => {
    setDropDown2(e);
  };

  const isPopUp = useSelector((store) => {
    return store.isPopUp;
  });

  const overlayHandler = (e) => {
    dispatch(storeAction.isPopUpHander(e.target.id));
  };
  useEffect(() => {
    CheckValid();
  }, [token]);

  const CheckValid = async () => {
    var Checkuser = await axios
      .get(`${process.env.REACT_APP_LOCAL_HOST_URL}/share?data=${token}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    if (Checkuser.timestamp !== undefined) {
      setdecodeemail(Checkuser.email);
      setcandidateid(Checkuser.candidate_id);
      dispatch(storeAction.isPopUpHander("CheckUser"));
    } else {
      if (Checkuser.data.error === "Link has expired") {
        dispatch(storeAction.isPopUpHander("LinkExpired"));
      }
    }
  };

  const verifyBtn = async () => {
    setlinkloading(true);
    if (email.length == 0) {
      setTimeout(() => {
        setlinkloading(false);
        setMessage("Please enter your email");
      }, 2000);
    } else {
      if (decodeemail !== email) {
        setTimeout(() => {
          setlinkloading(false);
          setMessage("Email does not match. Please try again.");
        }, 2000);
      } else {
        setMessage("Email verified successfully!");
        var Getuserinfo = await axios
          .get(
            `${process.env.REACT_APP_LOCAL_HOST_URL}/get-staffuser/${candidateid}/`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${token}`,
              },
            }
          )
          .then((res) => {
            return res.data;
          })
          .catch((err) => {
            return err.response;
          });
        if (Getuserinfo) {
          setsingle_user([Getuserinfo]);
          dispatch(storeAction.isPopUpHander());
          setMessage("");
          setemail("");
          setlinkloading(false);
        } else {
          setlinkloading(false);
          setsingle_user([]);
          dispatch(storeAction.isPopUpHander());
          setMessage("");
          setemail("");
        }
      }
    }
  };
  return (
    <div>
      <div className="shareTopHead">
        <img
          src={logo}
          alt=""
          onClick={() => {
            window.location.replace("/");
          }}
        />
        <div className="shareTopHeadButton">
          <h5
            onClick={() => {
              window.location.replace("/#/login");
            }}
          >
            Log in
          </h5>
          <button
            onClick={() => {
              window.location.replace("/");
            }}
          >
            Sign up
          </button>
        </div>
      </div>
      {page == "page1" && (
        <div className="px-72">
          {single_user.length !== 0 && (
            <CandidateCard singleuser={single_user} />
          )}
        </div>
      )}
      {isPopUp == "evaluation" && (
        <>
          <div
            className={
              dropDown == "page2"
                ? "candidateEvaluationActive overlay"
                : "candidateEvaluation overlay"
            }
          >
            <div className="candidateEvaluationHead">
              <PiEyeglasses className="candidateEvaluationHeadIcon" />
              <h1>Candidate Evaluation</h1>
            </div>
            <div
              className={
                dropDown == "page2"
                  ? "candidateEvaluationBodyActive"
                  : "candidateEvaluationBody"
              }
            >
              <div className="candidateEvaluationContent">
                <input type="radio" />
                <h2>Hire candidate (No interview needed)</h2>
              </div>
              <div className="candidateEvaluationContent">
                <input type="radio" />
                <h2>Accept candidate (Schedule interview via HR)</h2>
              </div>
              <div className="candidateEvaluationContent">
                <input type="radio" />
                <h2>Schedule interview directly with candidate</h2>
              </div>
              <div>
                <div
                  className={
                    dropDown == "page2"
                      ? "candidateEvaluationContentActive"
                      : "candidateEvaluationContent"
                  }
                >
                  <input
                    onClick={() => dropDownHandler("page2")}
                    type="radio"
                  />
                  <h2>Reject candidate</h2>
                </div>
                {dropDown == "page2" && (
                  <>
                    <div className="candidateEvaluationContentDrop">
                      <h3>Reason for Rejection</h3>
                      <input
                        onClick={() => dropDownHandler1("SubDrop")}
                        placeholder="Select Reason"
                        type="text"
                      />
                      {dropDown1 == "SubDrop" && (
                        <>
                          <div className="candidateEvaluationSubContentDrop">
                            <div className="">
                              <div
                                onClick={() => dropDownHandler2("page3")}
                                className="flex justify-between items-center mb-2"
                              >
                                <h4>Skill set does not match</h4>
                                {dropDown2 == "page3" ? (
                                  <IoIosArrowUp />
                                ) : (
                                  <IoIosArrowDown />
                                )}
                              </div>
                              {dropDown2 == "page3" && (
                                <>
                                  <div className="">
                                    <h5 className="lightText">
                                      Assess the candidate's skill set on a
                                      scale from 1 (lowest) to 10 (highest).
                                    </h5>
                                  </div>
                                </>
                              )}
                            </div>
                            <div className="">
                              <div
                                onClick={() => dropDownHandler2("page4")}
                                className="flex justify-between items-center mb-2"
                              >
                                <h4>Prefer onsite placement </h4>
                                {dropDown2 == "page4" ? (
                                  <IoIosArrowUp />
                                ) : (
                                  <IoIosArrowDown />
                                )}
                              </div>
                              {dropDown2 == "page4" && <></>}
                            </div>
                            <div className="">
                              <div
                                onClick={() => dropDownHandler2("page5")}
                                className="flex justify-between items-center mb-2"
                              >
                                <h4>Not yet ready to hire</h4>
                                {dropDown2 == "page5" ? (
                                  <IoIosArrowUp />
                                ) : (
                                  <IoIosArrowDown />
                                )}
                              </div>{" "}
                              {dropDown2 == "page5" && (
                                <>
                                  <div className="preferContent">
                                    <textarea
                                      id="w3review"
                                      name="w3review"
                                      rows="4"
                                      cols="50"
                                    ></textarea>
                                  </div>
                                </>
                              )}
                            </div>
                            <div className="">
                              <div
                                onClick={() => dropDownHandler2("page6")}
                                className="flex justify-between items-center mb-2"
                              >
                                <h4>Pass through to next interviewer</h4>
                                {dropDown2 == "page6" ? (
                                  <IoIosArrowUp />
                                ) : (
                                  <IoIosArrowDown />
                                )}
                              </div>{" "}
                              {dropDown2 == "page6" && (
                                <>
                                  <div className="">
                                    <h5 className="lightText">
                                      Transfer candidate for further evaluation
                                    </h5>
                                  </div>
                                </>
                              )}
                            </div>
                            <div className="">
                              <div
                                onClick={() => dropDownHandler2("page7")}
                                className="flex justify-between items-center mb-2"
                              >
                                <h4>Leave a comment</h4>
                                {dropDown2 == "page7" ? (
                                  <IoIosArrowUp />
                                ) : (
                                  <IoIosArrowDown />
                                )}
                              </div>{" "}
                              {dropDown2 == "page7" && (
                                <>
                                  <div className="preferContent">
                                    <textarea
                                      id="w3review"
                                      name="w3review"
                                      rows="4"
                                      cols="50"
                                    ></textarea>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="candidateEvaluationButton">
              <button className="candidateEvaluationButtonClose">Close</button>
              <button
                id="hiringprocessinitiated"
                onClick={overlayHandler}
                className="candidateEvaluationButtonConfirm"
              >
                Confirm
              </button>
            </div>
          </div>
        </>
      )}
      {isPopUp == "hiringprocessinitiated" && (
        <>
          <div className="evaluationSuccessPopup overlay">
            <SuccessResponse
              title="Hiring Process Initiated"
              des="Notification has been sent to the hirer to complete the hiring process with Hirein5."
            />
            <button className="candidateEvaluationButtonConfirm">
              Continue
            </button>
          </div>
        </>
      )}
      {isPopUp == "CheckUser" && (
        <>
          <div className="approveCandidateOverlay">
            <div className="candidateRateCardOverlayHead">
              <h1>Verify Email Address</h1>
            </div>
            <div className="approveCandidateOverlayBody">
              <h1 className="font-medium text-sm mt-2 px-4 text-[#A1A1AA] uppercase">
                Please verify the email address to proceed
              </h1>
              <div className="flex gap-4 px-4 mt-5 w-full items-center mb-5">
                <div className="flex flex-col w-full">
                  <label className="font-medium text-sm mb-2">
                    Enter Email Address
                  </label>
                  <input
                    type="text"
                    className="rounded w-full border py-2 px-5"
                    placeholder="name@gmail.com"
                    aria-label="Email input"
                    name="email"
                    onChange={(e) => {
                      setemail(e.target.value);
                    }}
                  />
                  {/* Reserve space for the message */}
                  <h6
                    className={`text-sm mt-2 font-medium transition-all ${
                      message.includes("successfully")
                        ? "text-green-600"
                        : "text-red-600"
                    }`}
                    style={{
                      minHeight: "20px",
                    }}
                  >
                    {message}
                  </h6>
                </div>
                {linkloading == true ? (
                  <button className="sendinviteBtn1">Verifying...</button>
                ) : (
                  <button className="sendinviteBtn1" onClick={verifyBtn}>
                    Verify Email
                  </button>
                )}
              </div>
            </div>
          </div>
        </>
      )}
      {isPopUp == "LinkExpired" && (
        <>
          <div className="approveCandidateOverlay">
            <div className="linkExpiredOverlay">
              <div className="linkExpiredContent">
                <h1 className="text-lg font-bold">Link Expired</h1>
                <p className="text-sm text-gray-600 mt-2">
                  The link you are trying to access is no longer valid. Please
                  request a new one.
                </p>
                <button
                  className="closeButton"
                  onClick={() => {
                    window.location.replace("/");
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ShareComp;
