/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaRegCircleXmark } from "react-icons/fa6";
import { FiPlusCircle } from "react-icons/fi";
import { IoEye } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { storeAction } from "../../../Store/Store";
import * as XLSX from "xlsx";

const AssessmentFlow = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((store) => store.token);
  const [teamlist, setteamlist] = useState([
    {
      id: uuidv4(),
      first_name: "",
      last_name: "",
      vendor_name: "",
      date_sent1: "",
      scheduled_date: "",
      scheduled_time: "",
      status: "",
      score: "",
      date_report_received: "",
      final_report_link: "",
      result: "",
      comments: "",
      assessment_date: "",
      conducted_by: "",
      previous_compensation: "",
      ready_to_sign_mou: "",
      engagement_model: "",
      communication: "",
      attitude: "",
      compensation_offered: "",
      mou_signed1: "",
      outcome: "",
      additional_comments: "",
      date_sent: "",
      sent_by: "",
      mou_signed: "",
      mou_signed_date: "",
      vendor_name_initiated: "",
      date_initiated: "",
      initiated_by: "",
      interim_report_received: "",
      bgv_status: "",
      final_report_received: "",
      final_report_comments: "",
      isNew: true,
      move: "false",
    },
  ]);
  const [loading, setloading] = useState(false);
  const [loading1, setloading1] = useState(false);
  const [count, setcount] = useState(0);
  const [pagination_status, setpagination_status] = useState(false);
  const [newurl, setnewurl] = useState(null);
  const [candidatenext, setcandidatenext] = useState(null);
  const [candidateprevious, setcandidateprevious] = useState(null);
  const [postmoudata, setpostmoudata] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [searchname, setsearchname] = useState("");
  const [searchfilter, setsearchfilter] = useState(false);
  const [searchloading, setsearchloading] = useState(false);

  const handleChangeNew = (index, field, value) => {
    const updatedTeams = teamlist.map((candidate, cIndex) => {
      if (cIndex === index) {
        return {
          ...candidate,
          [field]: value,
        };
      }
      return candidate;
    });
    setteamlist(updatedTeams);
  };

  // const addCandidate = () => {
  //   const newCandidate = {
  //     id: uuidv4(),
  //     first_name: "",
  //     last_name: "",
  //     vendor_name: "",
  //     date_sent1: "",
  //     scheduled_date: "",
  //     scheduled_time: "",
  //     status: "",
  //     score: "",
  //     date_report_received: "",
  //     final_report_link: "",
  //     result: "",
  //     comments: "",
  //     assessment_date: "",
  //     conducted_by: "",
  //     previous_compensation: "",
  //     ready_to_sign_mou: "",
  //     engagement_model: "",
  //     communication: "",
  //     attitude: "",
  //     compensation_offered: "",
  //     mou_signed1: "",
  //     outcome: "",
  //     additional_comments: "",
  //     date_sent: "",
  //     sent_by: "",
  //     mou_signed: "",
  //     mou_signed_date: "",
  //     vendor_name_initiated: "",
  //     date_initiated: "",
  //     initiated_by: "",
  //     interim_report_received: "",
  //     bgv_status: "",
  //     final_report_received: "",
  //     final_report_comments: "",
  //     isNew: true,
  //   };

  //   setteamlist([...teamlist, newCandidate]);
  // };

  // const removeCandidate = (candidateId) => {
  //   const updatedTeamlist = teamlist.filter(
  //     (candidate) => candidate.id !== candidateId
  //   );
  //   setteamlist(updatedTeamlist);
  // };

  const submitbtn = async () => {
    if (teamlist.length !== 0) {
      setloading(true);
      dispatch(storeAction.isPopUpHander("loading"));
      for (let i = 0; i < teamlist.length; i++) {
        const candidate = teamlist[i];
        const newCandidateData = {
          first_name: candidate.first_name,
          last_name: candidate.last_name,
          vendor_name: candidate.vendor_name,
          date_sent1: candidate.date_sent1,
          scheduled_date: candidate.scheduled_date,
          scheduled_time: candidate.scheduled_time,
          status: candidate.status,
          score: candidate.score,
          date_report_received: candidate.date_report_received,
          final_report_link: candidate.final_report_link,
          result: candidate.result,
          comments: candidate.comments,
          assessment_date: candidate.assessment_date,
          conducted_by: candidate.conducted_by,
          previous_compensation: candidate.previous_compensation,
          ready_to_sign_mou: candidate.ready_to_sign_mou,
          engagement_model: candidate.engagement_model,
          communication: candidate.communication,
          attitude: candidate.attitude,
          compensation_offered: candidate.compensation_offered,
          mou_signed1: candidate.mou_signed1,
          outcome: candidate.outcome,
          additional_comments: candidate.additional_comments,
          date_sent: candidate.date_sent,
          sent_by: candidate.sent_by,
          mou_signed: candidate.mou_signed,
          mou_signed_date: candidate.mou_signed_date,
          vendor_name_initiated: candidate.vendor_name_initiated,
          date_initiated: candidate.date_initiated,
          initiated_by: candidate.initiated_by,
          interim_report_received: candidate.interim_report_received,
          bgv_status: candidate.bgv_status,
          final_report_received: candidate.final_report_received,
          final_report_comments: candidate.final_report_comments,
        };
        if (candidate.isNew) {
          await axios
            .post(
              `${process.env.REACT_APP_LOCAL_HOST_URL}/assessment/`,
              newCandidateData,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `JWT ${token}`,
                },
              }
            )
            .then((res) => {
              candidate.isNew = false;
            })
            .catch((err) => console.error(err));
        } else {
          await axios
            .put(
              `${process.env.REACT_APP_LOCAL_HOST_URL}/assessment/${candidate.id}/`,
              newCandidateData,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `JWT ${token}`,
                },
              }
            )
            .catch((err) => console.error(err));
        }
      }
      setloading(false);
      setTimeout(() => {
        dispatch(storeAction.isPopUpHander());
      }, 2000);
    }
  };

  useEffect(() => {
    Getalldata();
  }, []);

  const Getalldata = async () => {
    const Alldata = await axios
      .get(`${process.env.REACT_APP_LOCAL_HOST_URL}/assessment/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
      })
      .then((res) => res.data)
      .catch((err) => err.response);

    if (Alldata.results.length !== 0) {
      setcount(Alldata.count);
      if (Alldata.count > 20) {
        setpagination_status(true);
      } else {
        setpagination_status(false);
      }
      setcandidateprevious(Alldata.previous);
      setcandidatenext(Alldata.next);
      const updatedData = Alldata.results.map((candidate) => ({
        ...candidate,
        isNew: false,
        move: "false",
      }));
      setteamlist(updatedData);
    }
    const AllAsseData = await axios
      .get(`${process.env.REACT_APP_LOCAL_HOST_URL}/mou-approval/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
      })
      .then((res) => res.data)
      .catch((err) => err.response);
    if (AllAsseData.results.length !== 0) {
      setpostmoudata(AllAsseData.results);
    }
  };

  const moveToass = async () => {
    setloading1(true);
    dispatch(storeAction.isPopUpHander("loading"));
    teamlist.forEach(async (teamMember) => {
      const matchedAssessment = postmoudata.find(
        (assessment) => assessment.email === teamMember.email
      );
      if (teamMember.move == "true") {
        if (matchedAssessment) {
          const obj = {
            email: teamMember.email,
            first_name: teamMember.first_name,
            last_name: teamMember.last_name,
          };
          await axios
            .put(
              `${process.env.REACT_APP_LOCAL_HOST_URL}/mou-approval/${matchedAssessment.id}/`,
              obj,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `JWT ${token}`,
                },
              }
            )
            .catch((err) => console.error(err));
        } else {
          const obj1 = {
            email: teamMember.email,
            first_name: teamMember.first_name,
            last_name: teamMember.last_name,
          };
          await axios
            .post(
              `${process.env.REACT_APP_LOCAL_HOST_URL}/mou-approval/`,
              obj1,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `JWT ${token}`,
                },
              }
            )
            .catch((err) => console.error(err));
        }
      }
    });
    Getalldata();
    setTimeout(() => {
      setloading1(false);
      dispatch(storeAction.isPopUpHander());
    }, 2000);
  };

  const exportBtn = async () => {
    if (filteredData.length !== 0) {
      const manipulatedData = filteredData.map((item) => {
        return {
          First_Name: item.first_name,
          Last_Name: item.last_name,
          Vendor_Name: item.vendor_name,
          Date_sent: item.date_sent,
          Scheduled_date: item.scheduled_date,
          Scheduled_time: item.scheduled_time,
          Status: item.status,
          Score: item.score,
          Date_report_received: item.date_report_received,
          Result: item.result,
          Comments: item.comments,
          Conducted_by: item.conducted_by,
          Previous_compensation: item.previous_compensation,
          Additional_comments: item.additional_comments,
          Assessment_date: item.assessment_date,
          Attitude: item.attitude,
          Bgv_status: item.bgv_status,
          Communication: item.communication,
          Compensation_offered: item.compensation_offered,
          Date_initiated: item.date_initiated,
          Date_sent1: item.date_sent1,
          Engagement_model: item.engagement_model,
          Final_report_comments: item.final_report_comments,
          Final_report_link: item.final_report_link,
          Final_report_received: item.final_report_received,
          Initiated_by: item.initiated_by,
          Interim_report_received: item.interim_report_received,
          Mou_signed: item.mou_signed,
          Notice_period_Status: item.mou_signed1,
          Mou_signed_date: item.mou_signed_date,
          Outcome: item.outcome,
          Ready_to_sign_mou: item.ready_to_sign_mou,
          Sent_by: item.sent_by,
          Vendor_name_initiated: item.vendor_name_initiated,
        };
      });
      const worksheet = XLSX.utils.json_to_sheet(manipulatedData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, "Assessment Flow.xlsx");
    }
  };

  const handleDateChange = (type, value) => {
    if (type === "from") {
      setFromDate(value);
    } else {
      setToDate(value);
    }
  };

  const filterByDate = (data) => {
    if (!fromDate && !toDate) return data; // If no dates are selected, return all data

    const from = fromDate ? new Date(fromDate) : null;
    const to = toDate ? new Date(toDate) : null;

    return data.filter((candidate) => {
      const callDate = new Date(
        candidate.date_sent1 || candidate.scheduled_date
      );
      return (!from || callDate >= from) && (!to || callDate <= to);
    });
  };

  useEffect(() => {
    GetnewCandidate();
  }, [newurl]);

  const GetnewCandidate = async () => {
    if (newurl !== null) {
      dispatch(storeAction.isPopUpHander("loading"));
      const response = await axios
        .get(`${newurl}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        })
        .then((res) => {
          return res.data;
        });
      if (response.results.length !== 0) {
        setcount(response.count);
        if (response.count > 20) {
          setpagination_status(true);
        } else {
          setpagination_status(false);
        }
        setcandidateprevious(response.previous);
        setcandidatenext(response.next);
        const updatedData = response.results.map((candidate) => ({
          ...candidate,
          isNew: false,
          move: "false",
        }));
        setteamlist(updatedData);
        dispatch(storeAction.isPopUpHander());
      }
    }
  };

  const searchbtn = async () => {
    if (searchname.length !== 0) {
      setsearchloading(true);
      var obj = {
        name: searchname,
      };
      const Alldata = await axios
        .post(
          `${process.env.REACT_APP_LOCAL_HOST_URL}/assessment-flow-name-filters/`,
          obj,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${token}`,
            },
          }
        )
        .then((res) => res.data)
        .catch((err) => err.response);
      if (Alldata.results.length !== 0) {
        setsearchloading(false);
        setsearchfilter(true);
        setcount(Alldata.count);
        if (Alldata.count > 20) {
          setpagination_status(true);
        } else {
          setpagination_status(false);
        }
        setcandidateprevious(Alldata.previous);
        setcandidatenext(Alldata.next);
        const updatedData = Alldata.results.map((candidate) => ({
          ...candidate,
          isNew: false,
          isFrom: "db",
        }));
        setteamlist(updatedData);

        dispatch(storeAction.isPopUpHander());
      } else {
        setsearchloading(false);
        setsearchfilter(false);
      }
    } else {
      setsearchloading(false);
      setsearchfilter(false);
      alert("Please Enter Search First & Middle Name or Last Name...");
    }
  };

  const filteredData = filterByDate(teamlist);
  const nPages = Math.ceil(count / 20);
  const pageNumbers = Array.from({ length: nPages }, (_, i) => i + 1);
  return (
    <div>
      <div className="flex gap-2 items-center">
        <div className="flex gap-2">
          <div className="flex flex-col">
            <input
              type="date"
              className="border border-[#7e3794] rounded p-1"
              value={fromDate}
              onChange={(e) => handleDateChange("from", e.target.value)}
              min="1000-01-01" 
                      max="9999-12-31"
            />
          </div>
          <div className="flex flex-col">
            <input
              type="date"
              className="border border-[#7e3794] rounded p-1"
              value={toDate}
              min="1000-01-01" 
                      max="9999-12-31"
              onChange={(e) => handleDateChange("to", e.target.value)}
            />
          </div>
        </div>
        <button className="importbtn" onClick={exportBtn}>
          Export
        </button>
      </div>
      <div className="flex gap-2 items-center">
        <input
          type="text"
          className="w-[20%] mt-2 mb-4 px-4 py-2 border border-gray-200 rounded"
          placeholder="Search First & Middle Name or Last Name"
          onChange={(e) => setsearchname(e.target.value)}
          value={searchname}
        />
        {searchloading == true ? (
          <button className="w-[5%] mt-2 mb-4 px-4 py-2 border border-[#1D37E7] rounded bg-[#1D37E7] text-white font-medium">
            Searching...
          </button>
        ) : (
          <button
            className="w-[5%] mt-2 mb-4 px-4 py-2 border border-[#1D37E7] rounded bg-[#1D37E7] text-white font-medium"
            onClick={searchbtn}
          >
            Search
          </button>
        )}

        {searchfilter && (
          <button
            className="w-[5%] mt-2 mb-4 px-4 py-2 border border-[#1D37E7] rounded bg-black text-white font-medium"
            onClick={() => {
              setsearchfilter(false);
              Getalldata();
              setsearchname("");
            }}
          >
            Cancel
          </button>
        )}
      </div>
      <table>
        <thead>
          <tr>
            <th colSpan={14} className="bg-blue-500 text-white py-2">
              Technical Interview
            </th>
            <th colSpan={11} className="bg-green-500 text-white py-2">
              HR Round
            </th>
            <th colSpan={4} className="bg-orange-300 text-white py-2">
              MoU
            </th>
            <th colSpan={8} className="bg-orange-500 text-white py-2">
              BGV
            </th>
          </tr>
          <tr className="hireTableHead">
            <th>Action</th>
            <th>View</th>
            <th>First & Middle Name</th>
            <th>Last Name</th>
            <th>Vendor Name</th>
            <th>Date Sent</th>
            <th>Scheduled Date</th>
            <th>Scheduled Time</th>
            <th>Status</th>
            <th>Score</th>
            <th>Date Report received</th>
            <th>Date final report & link received</th>
            <th>Result</th>
            <th>Comments</th>
            <th>HR Round Date</th>
            <th>Conducted By</th>
            <th>Previous Compensation</th>
            <th>Ready to sign MoU</th>
            <th>Model of engagement</th>
            <th>Communication</th>
            <th>Attitude</th>
            <th>Annual Compensation Offered</th>
            <th>Notice Period Status</th>
            <th>HR Outcome</th>
            <th>HR Comments</th>
            <th>Date Sent</th>
            <th>Sent by</th>
            <th>MoU Signed</th>
            <th>MoU Signed date</th>
            {/* <th>Comments</th> */}
            <th>Vendor Name</th>
            <th>Date Initiated</th>
            <th>Initiated by</th>
            <th>Interim Report Received</th>
            <th>BGV Status</th>
            <th>Final Report Received</th>
            <th>Comments</th>
          </tr>
        </thead>
        <tbody className="hireTableBody">
          {filteredData.length !== 0 &&
            filteredData.map((role, roleIndex) => (
              <tr className="hireTablerow" key={roleIndex}>
                {/* <td>
                  <div className="flex items-center justify-center">
                    <FaRegCircleXmark
                      className="xIcon"
                      onClick={() => removeCandidate(0, role.id)}
                    />
                    <FiPlusCircle
                      className="plusIcon"
                      onClick={() => addCandidate(0)}
                    />
                  </div>
                </td> */}
                <td>
                  <h3>
                    <input
                      type="checkbox"
                      checked={role.move == "true"}
                      onChange={() => {
                        handleChangeNew(
                          roleIndex,
                          "move",
                          role.move == "true" ? "false" : "true"
                        );
                      }}
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    {role.staff_data !== null ? (
                      <IoEye
                        className="plusIcon cursor-pointer"
                        onClick={() => {
                          dispatch(
                            storeAction.singleuserHander({
                              singleuser: [role.staff_data],
                            })
                          );
                          navigate("/admincandidateview");
                        }}
                      />
                    ) : null}
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.first_name}
                      type="text"
                      disabled
                      onChange={(e) =>
                        handleChangeNew(roleIndex, "first_name", e.target.value)
                      }
                    />
                    {/* {role.first_name} */}
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.last_name}
                      type="text"
                      disabled
                      onChange={(e) =>
                        handleChangeNew(roleIndex, "last_name", e.target.value)
                      }
                    />
                    {/* {role.last_name} */}
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.vendor_name}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "vendor_name",
                          e.target.value
                        )
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.date_sent1}
                      type="date"
                      min="1000-01-01" 
                      max="9999-12-31"
                      onChange={(e) =>
                        handleChangeNew(roleIndex, "date_sent1", e.target.value)
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.scheduled_date}
                      min="1000-01-01" 
                      max="9999-12-31"
                      type="date"
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "scheduled_date",
                          e.target.value
                        )
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.scheduled_time}
                      type="time"
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "scheduled_time",
                          e.target.value
                        )
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <select
                      className="hireTableBodySelect"
                      value={role.status}
                      onChange={(e) =>
                        handleChangeNew(roleIndex, "status", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Scheduled">Scheduled</option>
                      <option value="Completed">Completed</option>
                      <option value="Pending">Pending</option>
                    </select>
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.score}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(roleIndex, "score", e.target.value)
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.date_report_received}
                      type="date"
                      min="1000-01-01" 
                      max="9999-12-31"
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "date_report_received",
                          e.target.value
                        )
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.final_report_link}
                      type="date"
                      min="1000-01-01" 
                      max="9999-12-31"
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "final_report_link",
                          e.target.value
                        )
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <select
                      className="hireTableBodySelect"
                      value={role.result}
                      onChange={(e) =>
                        handleChangeNew(roleIndex, "result", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Passed">Passed</option>
                      <option value="Failed">Failed</option>
                    </select>
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.comments}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(roleIndex, "comments", e.target.value)
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.assessment_date}
                      type="date"
                      min="1000-01-01" 
                      max="9999-12-31"
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "assessment_date",
                          e.target.value
                        )
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.conducted_by}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "conducted_by",
                          e.target.value
                        )
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.previous_compensation}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "previous_compensation",
                          e.target.value
                        )
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.ready_to_sign_mou}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "ready_to_sign_mou",
                          e.target.value
                        )
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <select
                      className="hireTableBodySelect"
                      value={role.engagement_model}
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "engagement_model",
                          e.target.value
                        )
                      }
                    >
                      <option value="">Select</option>
                      <option value="Part time Consultant">
                        Part time Consultant
                      </option>
                      <option value="Full time Consultant">
                        Full time Consultant
                      </option>
                      <option value="Employee">Employee</option>
                    </select>
                  </h3>
                </td>
                <td>
                  <h3>
                    <select
                      className="hireTableBodySelect"
                      value={role.communication}
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "communication",
                          e.target.value
                        )
                      }
                    >
                      <option value="">Select</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </select>
                  </h3>
                </td>
                <td>
                  <h3>
                    <select
                      className="hireTableBodySelect"
                      value={role.attitude}
                      onChange={(e) =>
                        handleChangeNew(roleIndex, "attitude", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </select>
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.compensation_offered}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "compensation_offered",
                          e.target.value
                        )
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.mou_signed1}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "mou_signed1",
                          e.target.value
                        )
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <select
                      className="hireTableBodySelect"
                      value={role.outcome}
                      onChange={(e) =>
                        handleChangeNew(roleIndex, "outcome", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Approved">Approved</option>
                      <option value="Rejected">Rejected</option>
                    </select>
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.additional_comments}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "additional_comments",
                          e.target.value
                        )
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.date_sent}
                      type="date"
                      min="1000-01-01" 
                      max="9999-12-31"
                      onChange={(e) =>
                        handleChangeNew(roleIndex, "date_sent", e.target.value)
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.sent_by}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(roleIndex, "sent_by", e.target.value)
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <select
                      className="hireTableBodySelect"
                      value={role.mou_signed}
                      onChange={(e) =>
                        handleChangeNew(roleIndex, "mou_signed", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.mou_signed_date}
                      type="date"
                      min="1000-01-01" 
                      max="9999-12-31"
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "mou_signed_date",
                          e.target.value
                        )
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.vendor_name_initiated}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "vendor_name_initiated",
                          e.target.value
                        )
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.date_initiated}
                      type="date"
                      min="1000-01-01" 
                      max="9999-12-31"
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "date_initiated",
                          e.target.value
                        )
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.initiated_by}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "initiated_by",
                          e.target.value
                        )
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.interim_report_received}
                      type="date"
                      min="1000-01-01" 
                      max="9999-12-31"
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "interim_report_received",
                          e.target.value
                        )
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <select
                      className="hireTableBodySelect"
                      value={role.bgv_status}
                      onChange={(e) =>
                        handleChangeNew(roleIndex, "bgv_status", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="In progress">In progress</option>
                      <option value="Completed">Completed</option>
                      <option value="Yet to initiate">Yet to initiate</option>
                      <option value="Document upload pending from candidates end">
                        Document upload pending from candidates end
                      </option>
                      <option value="On hold-College not supportive">
                        On hold-College not supportive
                      </option>
                      <option value="On hold-Company not supportive">
                        On hold-Company not supportive
                      </option>
                      <option value="On hold-Candidate not okay with Physical verification">
                        On hold-Candidate not okay with Physical verification
                      </option>
                      <option value="On hold-Currently candidate is not available for physical verification">
                        On hold-Currently candidate is not available for
                        physical verification
                      </option>
                      <option value="On hold-College verification fee is high">
                        On hold-College verification fee is high
                      </option>
                      <option value="Need to proceed with digital verification as candidates lives in remote location">
                        Need to proceed with digital verification as candidates
                        lives in remote location
                      </option>
                    </select>
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.final_report_received}
                      type="date"
                      min="1000-01-01" 
                      max="9999-12-31"
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "final_report_received",
                          e.target.value
                        )
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.final_report_comments}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "final_report_comments",
                          e.target.value
                        )
                      }
                    />
                  </h3>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      {loading ? (
        <button className="saveSubmit">Please Wait...</button>
      ) : (
        <button className="saveSubmit" onClick={submitbtn}>
          Save Changes
        </button>
      )}
      {loading1 ? (
        <button className="saveSubmit1">Please Wait...</button>
      ) : (
        <button className="saveSubmit1" onClick={moveToass}>
          Move to Post Mou PreApproval
        </button>
      )}
      {pagination_status && (
        <div className="tablePagination">
          <nav>
            <ul className="pagination">
              {candidateprevious == null ? (
                <li className="page-item disabled">
                  <h6 className="page-link">&lt;&lt; Previous</h6>
                </li>
              ) : (
                <li
                  className="page-item active"
                  onClick={() => {
                    setnewurl(candidateprevious);
                  }}
                >
                  <h6 className="page-link">&lt;&lt; Previous</h6>
                </li>
              )}

              {newurl == null ? (
                <h6 className="page-link">1</h6>
              ) : (
                <h6 className="page-link active">
                  {newurl.includes("/assessment/") &&
                  newurl.split("/assessment/")[1]?.split("?page=")[1] !==
                    undefined
                    ? newurl.split("/assessment/")[1].split("?page=")[1]
                    : 1}
                </h6>
              )}
              {candidatenext == null ? (
                <li className="page-item disabled">
                  <h6 className="page-link">Next &gt;&gt;</h6>
                </li>
              ) : (
                <li
                  className="page-item active"
                  onClick={() => {
                    setnewurl(candidatenext);
                  }}
                >
                  <h6 className="page-link">Next &gt;&gt;</h6>
                </li>
              )}
            </ul>
          </nav>
          <p className="text-center mt-4 font-medium text-xs text-[#71717a]">
            Total No Of Pages : {pageNumbers.length}
          </p>
        </div>
      )}
    </div>
  );
};

export default AssessmentFlow;
