/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import { useSelector } from "react-redux";

const ChatComponent = () => {
  const token = useSelector((store) => store.token);
  const [questionsList, setQuestionsList] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [timeLeft, setTimeLeft] = useState(60);
  const [startTime, setStartTime] = useState(null);
  const [responsedata, setresponsedata] = useState(""); // Store HTML response here

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      handleNext();
    }
  }, [timeLeft]);

  const fetchQuestions = async () => {
    const userMessage = {
      role: "user",
      content: `
        I am a html,css developer. I would like to go through a technical assessment. Can you please provide me with 12 questions and evaluate my answers?
        Please provide all these questions in an array of objects, where the "question" field is not an empty string, and the "answer" and "score" fields are empty strings.
        **Rules to follow:**
        1. Each time, generate some variation in the questions.
        2. Only provide the response in an array of objects.
        3. Do not add any extra text in the response.
        4. Based on the experience level, increase the complexity of the questions.
        5. Include exactly 3 coding questions.
        6. Add an extra field named "type" with values either "coding" or "theory" to differentiate between coding and theory questions.
        7. Follow all the above rules strictly.
        8. Provide the same response format every time, without deviations.
        9. only json format data needed . no javascript or jsx
      `,
    };

    try {
      const response = await fetch(
        "https://shark-app-5pxme.ondigitalocean.app/api/chat",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ messages: [userMessage] }),
        }
      );
      const data = await response.json();
      const rawResponse = data.response.replace(/```[\w]*\n|```/g, "").trim();
      const parsedData = JSON.parse(rawResponse);

      setQuestionsList(parsedData);
      setStartTime(Date.now());
    } catch (error) {
      console.error("Error fetching questions:", error);
    }
  };

  useEffect(() => {
    fetchQuestions();
  }, []);

  const handleNext = () => {
    if (currentQuestionIndex < questionsList.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setTimeLeft(60);
    }
  };

  const handleSubmit = async () => {
    const endTime = Date.now();
    const timeTaken = Math.round((endTime - startTime) / 1000);
    let string = questionsList.map((obj) => JSON.stringify(obj)).join(", ");
    const userMessage = {
      role: "user",
      content: `${string} these are the questions and answers answered by a candidate. Can you please evaluate this and give an overall performance report? I know you are an AI and cannot generate a score, but I believe you and trust your evaluation, so kindly give me the report. 
      Rules to follow:
      1. If the answer is wrong, please provide 0 marks.
      2. Provide this as an HTML format. I know you have a high creative power, so make the document creative.
      3. HTML format is a must for the response.
      4. Provide areas of improvement.
      5. Suggest how to improve in detail.
      6. Highlight areas of weakness in detail.
      7. Highlight areas of strength in detail.
      8. Score and feedback are mandatory.
      9. Include the following for each question: - Given Question - Given Answer - Correct Answer - Score - Feedback .
      10. At the end of the document, include: - Areas of Improvement - Suggestions on How to Improve in Detail - Highlight Areas of Weakness in Detail.
      11. please provide report for all the questions take ur own time. time is not a problem for us.`,
    };

    try {
      const response = await fetch(
        "https://shark-app-5pxme.ondigitalocean.app/api/chat",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ messages: [userMessage] }),
        }
      );
      const data = await response.json();
      setresponsedata(data.response); // Store the HTML response directly
    } catch (error) {
      console.error("Error fetching questions:", error);
    }
  };

  const handleInputChange = (e) => {
    const updatedQuestions = [...questionsList];
    updatedQuestions[currentQuestionIndex].answer = e.target.value;
    setQuestionsList(updatedQuestions);
  };

  const currentQuestion = questionsList[currentQuestionIndex];

  return (
    <Box sx={{ padding: 4, margin: "0 auto" }}>
      <Typography variant="h4" gutterBottom>
        Technical Assessment
      </Typography>

      <Stepper activeStep={currentQuestionIndex} alternativeLabel>
        {questionsList.map((_, index) => (
          <Step key={index}>
            <StepLabel>Question {index + 1}</StepLabel>
          </Step>
        ))}
      </Stepper>

      {currentQuestion && (
        <Box sx={{ marginTop: 4 }}>
          <Typography variant="h6">
            {`Q${currentQuestionIndex + 1}: ${currentQuestion.question}`}
          </Typography>

          <Typography sx={{ margin: "10px 0" }} color="error">
            Time Left: {timeLeft}s
          </Typography>

          <TextField
            label={
              currentQuestion.type === "coding"
                ? "Write your code here"
                : "Your Answer"
            }
            multiline={currentQuestion.type === "coding"}
            rows={currentQuestion.type === "coding" ? 6 : 1}
            variant="outlined"
            fullWidth
            value={currentQuestion.answer || ""}
            onChange={handleInputChange}
          />

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 3,
            }}
          >
            {currentQuestionIndex < questionsList.length - 1 ? (
              <Button variant="contained" color="primary" onClick={handleNext}>
                Next
              </Button>
            ) : (
              <Button
                variant="contained"
                color="success"
                onClick={handleSubmit}
              >
                Submit
              </Button>
            )}
          </Box>
        </Box>
      )}

      {/* Display the HTML response below */}
      {responsedata && (
        <Box sx={{ marginTop: 4 }}>
          <Typography variant="h5" gutterBottom>
            Performance Evaluation
          </Typography>
          <Box
            sx={{
              padding: 2,
              border: "1px solid #ccc",
              borderRadius: 2,
              backgroundColor: "#f9f9f9",
            }}
            dangerouslySetInnerHTML={{ __html: responsedata }}
          />
        </Box>
      )}
    </Box>
  );
};

export default ChatComponent;
