/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./AdminCandidateProfile.css";
import search from "../../../../assests/search.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "react-avatar";
import { storeAction } from "../../../../Store/Store";
import moment from "moment/moment";
import axios from "axios";

const AdminCandidateProfile = ({
  alldata,
  totaldata,
  loading,
  setalldata,
  candidatenext,
  candidateprevious,
  setnewurl,
  newurl,
  setcandidatenext,
  setcandidateprevious,
  Getcandidate,
  count,
  setcount,
  setpagination_status,
  pagination_status,
  settotaldata,
  statussearch,
  statussearchvalue,
  setstatussearch,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userid = useSelector((store) => store.userid);
  const userdata = useSelector((store) => store.userdata);
  const token = useSelector((store) => store.token);
  const loginrole = useSelector((store) => store.loginrole);
  const [search_value, setsearch_value] = useState("");
  const [search_status, setsearch_status] = useState(false);
  const [coundata, setcoundata] = useState({
    Benched: 0,
    Hired: 0,
    Reserved: 0,
    Archived: 0,
    Onboarding: 0,
    Submitted: 0,
    Non_Vetted: 0,
  });

  const viewbtn = (data) => {
    var newobj = {
      admin_id: userid,
      user_id: data.id,
      message: `${
        userdata[0].first_name.length !== 0
          ? userdata[0].first_name
          : "Super Admin"
      } is viewing the detailed profile information for candidate ${
        data.first_name
      }.`,
      date_time: moment().format(),
      activity: "View Candidate Profile",
    };
    dispatch(storeAction.singleuserHander({ singleuser: [data] }));
    navigate("/admincandidateview");
  };

  const calculateProfileCompletion = (profile) => {
    let count = 0;
    if (profile.address !== null) count += 1;
    if (profile.work_preference_info !== null) count += 1;
    if (
      (profile.professional_details_info !== null &&
        profile.professional_details_info.length !== 0) ||
      profile.fresher == true ||
      profile.freshers_status == true
    )
      count += 1;
    if (
      (profile.project_details_info !== null &&
        profile.project_details_info.length !== 0) ||
      profile.fresher == true ||
      profile.freshers_status == true
    )
      count += 1;
    if (
      (profile.certificate_info !== null &&
        profile.certificate_info.length !== 0) ||
      profile.no_certificate == true
    )
      count += 1;
    if (profile.travel_info !== null) count += 1;
    if (profile.education_info !== null && profile.education_info.length !== 0)
      count += 1;
    if (profile.video_resume !== null && profile.video_resume.length !== 0)
      count += 1;

    return count;
  };

  const searchbtn = async () => {
    if (search_value.length !== 0) {
      var obj = {
        first_name: search_value,
      };
      dispatch(storeAction.isPopUpHander("loading"));
      const response = await axios
        .post(`${process.env.REACT_APP_LOCAL_HOST_URL}/search/name/`, obj, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        })
        .then((res) => {
          return res.data;
        });
      if (response.results.length !== 0) {
        dispatch(storeAction.isPopUpHander());
        setcount(response.count);
        if (response.count > 20) {
          setpagination_status(true);
        } else {
          setpagination_status(false);
        }
        setalldata(response.results);
        setcandidateprevious(response.previous);
        setcandidatenext(response.next);
        setTimeout(() => {
          setsearch_status(true);
        }, 1000);
      } else {
        dispatch(storeAction.isPopUpHander());
      }
    }
  };

  useEffect(() => {
    GetnewCandidate();
  }, [newurl]);

  const GetnewCandidate = async () => {
    if (newurl !== null) {
      if (search_status == false) {
        if (statussearch == true) {
          dispatch(storeAction.isPopUpHander("loading"));
          var obj1 = {
            status: statussearchvalue,
          };
          const response = await axios
            .post(`${newurl}`, obj1, {
              headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${token}`,
              },
            })
            .then((res) => {
              return res.data;
            });
          if (response.results.length !== 0) {
            dispatch(storeAction.isPopUpHander());
            if (response.count > 20) {
              setpagination_status(true);
            } else {
              setpagination_status(false);
            }
            const sortedCandidates = [...response.results].sort((a, b) => {
              return new Date(b.updated_at) - new Date(a.updated_at);
            });
            setstatussearch(true);
            setalldata(sortedCandidates);
            setcandidateprevious(response.previous);
            setcandidatenext(response.next);
          } else {
            dispatch(storeAction.isPopUpHander());
            setstatussearch(false);
            setalldata([]);
            setcandidateprevious(null);
            setcandidatenext(null);
          }
        } else {
          dispatch(storeAction.isPopUpHander("loading"));
          setsearch_status(false);
          const response = await axios
            .get(`${newurl}`, {
              headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${token}`,
              },
            })
            .then((res) => {
              return res.data;
            });
          if (response.results.length !== 0) {
            dispatch(storeAction.isPopUpHander());
            if (response.count > 20) {
              setpagination_status(true);
            } else {
              setpagination_status(false);
            }
            const allFaculties = response.results || [];
            const sortedCandidates = [...allFaculties].sort((a, b) => {
              return new Date(b.updated_at) - new Date(a.updated_at);
            });

            setcandidateprevious(response.previous);
            setcandidatenext(response.next);
            setalldata(sortedCandidates);
            settotaldata(sortedCandidates);
          } else {
            dispatch(storeAction.isPopUpHander());
            setcandidateprevious(null);
            setcandidatenext(null);
            setalldata([]);
            settotaldata([]);
          }
        }
      } else {
        dispatch(storeAction.isPopUpHander("loading"));
        var obj = {
          first_name: search_value,
        };
        const response = await axios
          .post(`${newurl}`, obj, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${token}`,
            },
          })
          .then((res) => {
            return res.data;
          });
        if (response.results.length !== 0) {
          dispatch(storeAction.isPopUpHander());
          if (response.count > 20) {
            setpagination_status(true);
          } else {
            setpagination_status(false);
          }
          const sortedCandidates = [...response.results].sort((a, b) => {
            return new Date(b.updated_at) - new Date(a.updated_at);
          });
          setalldata(sortedCandidates);
          setcandidateprevious(response.previous);
          setcandidatenext(response.next);
        } else {
          dispatch(storeAction.isPopUpHander());
          setalldata([]);
          setcandidateprevious(null);
          setcandidatenext(null);
        }
      }
    }
    const countdata = await axios
      .get(`${process.env.REACT_APP_LOCAL_HOST_URL}/status-count/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
      })
      .then((res) => {
        return res.data;
      });
    if (countdata.Archived !== undefined) {
      setcoundata({
        Benched: countdata.Benched,
        Hired: countdata.Hired,
        Reserved: countdata.Reserved,
        Archived: countdata.Archived,
        Onboarding: countdata.Onboarding,
        Submitted: countdata.Submitted,
        Non_Vetted: countdata.Non_Vetted,
      });
    }
  };

  const nPages = Math.ceil(count / 20);
  const pageNumbers = Array.from({ length: nPages }, (_, i) => i + 1);
  return (
    <div>
      <div className="AdminClientProfileComp">
        <div className="flex justify-between gap-4 mb-5">
          <div className="card div-card">
            <h3>Onboarding</h3>
            <p>{coundata.Onboarding}</p>
          </div>
          <div className="card div-card">
            <h3>Submitted</h3>
            <p>{coundata.Submitted}</p>
          </div>
          <div className="card div-card">
            <h3>Available For Hire</h3>
            <p>{coundata.Benched}</p>
          </div>
          <div className="card div-card">
            <h3>Hired</h3>
            <p>{coundata.Hired}</p>
          </div>
          <div className="card div-card">
            <h3>Reserved</h3>
            <p>{coundata.Reserved}</p>
          </div>
          <div className="card div-card">
            <h3>Pre Approve Candidate</h3>
            <p>{coundata.Non_Vetted}</p>
          </div>
        </div>
        <div className="AdminClientProfileCompSearch">
          <input
            type="text"
            placeholder="Search..."
            onChange={(e) => setsearch_value(e.target.value)}
            id="seachinput"
          />
          <img src={search} alt="" />
          <div className="flex gap-4 divbutton">
            <button className="savesearch" onClick={searchbtn}>
              Search
            </button>
            {search_status && (
              <button
                className="cancelsearch"
                onClick={() => {
                  setsearch_status(false);
                  setalldata(totaldata);
                  document.getElementById("seachinput").value = "";
                  setpagination_status(true);
                  setnewurl(null);
                  setcandidateprevious(null);
                  setcandidatenext(null);
                  Getcandidate();
                }}
              >
                Cancel
              </button>
            )}
          </div>
        </div>
        <div className="AdminClientProfileCompTable">
          <table className="AdminClientTable">
            <thead>
              <tr className="AdminTableHead">
                <th>NAME</th>
                <th>LOCATION</th>
                <th>EMPLOYEE ID</th>
                <th>STATUS</th>
                <th>PROFILE COMPLETION</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {!loading ? (
                alldata.length !== 0 ? (
                  alldata.map((data, index) => {
                    const profileCompletion = calculateProfileCompletion(data);
                    const percent = Math.round((profileCompletion / 8) * 100);
                    return (
                      <tr className="adminTableRow" key={index}>
                        <td>
                          <div className="tableName">
                            {data.profile_picture.length !== 0 ? (
                              <img src={data.profile_picture} alt="" />
                            ) : (
                              <Avatar
                                name={data.first_name}
                                size={30}
                                round="50px"
                              />
                            )}
                            <h1>{data.first_name}</h1>
                          </div>
                        </td>
                        <td>
                          <div className="tableLocation">
                            {data.address !== null ? (
                              <h1>{data.address.country}</h1>
                            ) : (
                              <h1>-</h1>
                            )}
                          </div>
                        </td>
                        <td>
                          <h1>ID{data.employee_id}</h1>
                        </td>
                        <td>
                          {data.dissabled == false ? (
                            data.status === "Reserved" ? (
                              new Date(moment().format("YYYY-MM-DD")) <=
                              new Date(
                                moment(
                                  new Date(
                                    moment(data.block_expiry).format(
                                      "YYYY-MM-DD"
                                    )
                                  )
                                ).format("YYYY-MM-DD")
                              ) ? (
                                <p className="status reserving">
                                  {data.status}
                                </p>
                              ) : data.apprual === true ? (
                                <p className="processingstaus status">
                                  Available For Hire
                                </p>
                              ) : (
                                <p className="processingstaus status">
                                  {data.status}
                                </p>
                              )
                            ) : data.status === "Reject" ? (
                              <p className="status rejectstaus">
                                {data.status}
                              </p>
                            ) : data.status === "Hired" ? (
                              <p className="status hiringActive">
                                {data.status}
                              </p>
                            ) : (data.status === "Success" ||
                                data.status === "Mail send" ||
                                data.status === "Benched") &&
                              (data.nottify == true || data.nottify == false) &&
                              data.apprual == true ? (
                              <p className="processingstaus status">
                                Available For Hire
                              </p>
                            ) : (data.status === "Success" ||
                                data.status === "Mail send" ||
                                data.status === "Benched") &&
                              data.nottify === true &&
                              data.apprual === false &&
                              percent === 100 ? (
                              data.non_vetted == true ? (
                                <p className="status hiringActive">
                                  Pre Approved
                                </p>
                              ) : (
                                <p className="status hiringActive">Submitted</p>
                              )
                            ) : data.nottify !== true ? (
                              <p className="status contracted">Onboarding</p>
                            ) : data.status == "Benched" ||
                              (data.status == "Success" &&
                                data.nottify == true &&
                                data.apprual == false) ? (
                              <p className="status hiringActive">Submitted</p>
                            ) : null
                          ) : (
                            <p className="status rejectstaus">Disabled</p>
                          )}
                        </td>
                        <td>
                          <h1>{percent}%</h1>
                        </td>
                        <td>
                          <button
                            onClick={() => viewbtn(data)}
                            className="viewButton"
                          >
                            View
                          </button>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td></td>
                    <td></td>
                    <td>
                      <h6 className="text-center py-8">No data found...</h6>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                )
              ) : (
                <tr>
                  <td></td>
                  <td></td>
                  <td>
                    <h6 className="text-center py-8">Please wait...</h6>
                  </td>
                  <td></td>
                  <td></td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {/* {nPages > 1 ? (
          <div className="tablePagination">
            <Pagination
              nPages={nPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        ) : null} */}
        {pagination_status && (
          <div className="tablePagination">
            <nav>
              <ul className="pagination">
                {candidateprevious == null ? (
                  <li className="page-item disabled">
                    <h6 className="page-link">&lt;&lt; Previous</h6>
                  </li>
                ) : (
                  <li
                    className="page-item active"
                    onClick={() => {
                      setnewurl(candidateprevious);
                      document.getElementById("seachinput").value = "";
                    }}
                  >
                    <h6 className="page-link">&lt;&lt; Previous</h6>
                  </li>
                )}

                {newurl == null ? (
                  <h6 className="page-link">1</h6>
                ) : search_status === false ? (
                  statussearch == true ? (
                    <h6 className="page-link active">
                      {newurl.includes("/status/") &&
                      newurl.split("/status/")[1]?.split("?page=")[1] !==
                        undefined
                        ? newurl.split("/status/")[1].split("?page=")[1]
                        : 1}
                    </h6>
                  ) : (
                    <h6 className="page-link active">
                      {newurl.includes("/onboardedFaculties/") &&
                      newurl
                        .split("/onboardedFaculties/")[1]
                        ?.split("?page=")[1] !== undefined
                        ? newurl
                            .split("/onboardedFaculties/")[1]
                            .split("?page=")[1]
                        : 1}
                    </h6>
                  )
                ) : (
                  <h6 className="page-link active">
                    {newurl.includes("/name/") &&
                    newurl.split("/name/")[1]?.split("?page=")[1] !== undefined
                      ? newurl.split("/name/")[1].split("?page=")[1]
                      : 1}
                  </h6>
                )}

                {candidatenext == null ? (
                  <li className="page-item disabled">
                    <h6 className="page-link">Next &gt;&gt;</h6>
                  </li>
                ) : (
                  <li
                    className="page-item active"
                    onClick={() => {
                      setnewurl(candidatenext);
                      document.getElementById("seachinput").value = "";
                    }}
                  >
                    <h6 className="page-link">Next &gt;&gt;</h6>
                  </li>
                )}
              </ul>
            </nav>
            <p className="text-center mt-4 font-medium text-xs text-[#71717a]">
              Total No Of Pages : {pageNumbers.length}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminCandidateProfile;
